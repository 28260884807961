<template>
	<span :class="`${color}`" style="margin-right: 2px; font-size: 1.5rem;">{{ letter.letter }}</span>
</template>

<script>
export default {
	props: {
		letter: { type: Object }
	},
	computed: {
		color() {
			let color = null;
			if (this.letter.score < 0.6) {
				color = 'color_danger';
			} else if (this.letter.score <= 0.8) {
				color = 'color_warning';
			} else {
				color = 'color_primary';
			}
			return color;
		}
	},
}
</script>
<style>
.color_danger {
	color: #f05454;
}
.color_primary {
	color: #0c9463;
}

.color_warning {
	color: #ffd800;
}
</style>
